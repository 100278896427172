import { useCallback, useEffect, useMemo, useState } from 'react';
import { isETH } from 'consts';
import { useStore } from 'effector-react';
import { sendEventWithEventLabel, sendGAEvent } from 'googleAnalytics';
import { Lang } from 'lang';
import isNil from 'lodash/isNil';
import { deleteItemFromCart, editItemInCart } from 'models/cart';
import { currentLang$ } from 'models/language';
import { settings$ } from 'models/settings';
import { changeCountItemInCart } from 'utils/changeCountItemInCart';
import { getHiringDaysCount } from 'utils/datesСalculation';
import { deleteExtraFromCart } from 'utils/deleteExtraFromCart';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

export const useItemInCart = ({
  itemInCart,
  type,
  quantity,
  isLoading,
  indexItem,
  isPopup,
}) => {
  const { products, checkout, formatString } = Lang();

  const settings = useStore(settings$);
  const currentLangState = useStore(currentLang$);

  const [productInCartCount, setProductInCartCount] = useState(
    itemInCart.count,
  );

  useEffect(
    () => {
      if (type) {
        return;
      }

      setProductInCartCount(itemInCart.count);

      if (!isLoading && quantity !== productInCartCount) {
        setProductInCartCount(quantity);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, itemInCart, quantity],
  );

  const changeExtraModalTitle = useMemo(
    () =>
      type === 'optionalExtra' || type === 'optionalSaleExtra'
        ? checkout.optionalExtra
        : checkout.requiredExtra,
    [type, checkout],
  );

  const minQuantity = useMemo(
    () => (type === 'reqExtra' ? itemInCart.count : 1),
    [type, itemInCart.count],
  );

  const productInfo = useMemo(() => {
    return itemInCart.product || {};
  }, [itemInCart]);

  const productTitle = useMemo(() => {
    return getTranslatedFieldValue(
      productInfo.names,
      currentLangState,
      settings.defaultLanguage,
    );
  }, [currentLangState, productInfo.names, settings.defaultLanguage]);

  const { reqExtraList, optionalExtraList } = useMemo(() => {
    if (!productInfo?.attributes) {
      return { reqExtraList: [], optionalExtraList: [] };
    }

    return productInfo.attributes.reduce(
      (acc, el) => {
        if (el.slug === 'required-extra') {
          acc.reqExtraList.push({
            ...el,
            quantity:
              itemInCart.reqExtra.find((item) => item.id === el.id)?.quantity ||
              itemInCart.count,
          });
        }

        if (el.slug === 'optional-extra' || el.slug === 'optional-sale-extra') {
          acc.optionalExtraList.push({
            ...el,
            quantity:
              itemInCart.optionalExtra
                .concat(itemInCart.optionalSaleExtra)
                .find((item) => item.id === el.id)?.quantity || 0,
          });
        }

        return acc;
      },
      { reqExtraList: [], optionalExtraList: [] },
    );
  }, [itemInCart, productInfo]);

  const isLimit = useMemo(() => {
    return (
      isNil(productInfo.max_quantity) || quantity >= productInfo.max_quantity
    );
  }, [quantity, productInfo.max_quantity]);

  const daysCount = useMemo(() => {
    if (!itemInCart.dates.start || !itemInCart.dates.end) {
      return 0;
    }

    return getHiringDaysCount({
      dates: { end: itemInCart.dates?.end, start: itemInCart.dates?.start },
    });
  }, [itemInCart.dates]);

  const itemPriceText = useMemo(() => {
    if (isETH) {
      return daysCount === 1
        ? products.pricePerOneDay
        : formatString(products.pricePer, daysCount);
    }

    return products.other.perDay;
  }, [daysCount, formatString, products]);

  const changeExtra = useCallback(
    (extras) => {
      editItemInCart({
        ...itemInCart,
        reqExtra: extras.reqExtra,
        optionalExtra: extras.optionalExtra,
        optionalSaleExtra: extras.optionalSaleExtra,
      });
    },
    [itemInCart],
  );

  const handleChangeCount = useCallback(
    (count) => {
      sendEventWithEventLabel(
        'product_change_amount',
        isPopup ? 'resrvation_popup' : 'resrvation_page',
      );
      changeCountItemInCart(count, itemInCart, type, indexItem, productInfo);
    },
    [isPopup, itemInCart, type, indexItem, productInfo],
  );

  const onDateChangeCallback = useCallback(
    ({ startDate, endDate }) => {
      sendGAEvent(
        isPopup
          ? 'reservation_popup_change_hire_period'
          : 'reservation_page_change_hire_period',
      );

      editItemInCart({
        id: itemInCart.id,
        dates: { start: startDate, end: endDate },
      });
    },
    [isPopup, itemInCart.id],
  );

  const handleDelete = useCallback(() => {
    sendGAEvent(
      isPopup
        ? 'reservation_popup_delete_item'
        : 'reservation_page_delete_item',
    );

    !type
      ? deleteItemFromCart(itemInCart.id)
      : deleteExtraFromCart(itemInCart, type, indexItem);
  }, [indexItem, isPopup, itemInCart, type]);

  return useMemo(
    () => ({
      productTitle,
      itemPriceText,
      productInfo,
      reqExtraList,
      optionalExtraList,
      changeExtraModalTitle,
      daysCount,
      onDateChangeCallback,
      productInCartCount,
      minQuantity,
      handleChangeCount,
      isLimit,
      changeExtra,
      handleDelete,
    }),
    [
      productTitle,
      itemPriceText,
      productInfo,
      reqExtraList,
      optionalExtraList,
      changeExtraModalTitle,
      daysCount,
      onDateChangeCallback,
      productInCartCount,
      minQuantity,
      handleChangeCount,
      isLimit,
      changeExtra,
      handleDelete,
    ],
  );
};
