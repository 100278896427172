import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import { useGetProductDatesSettings } from 'hooks';
import {
  changeDatesRange,
  changeFocusedPickerDay,
  picker_state$,
} from 'models/cart';
import { productCalendarSettings$ } from 'models/common';
import {
  formatDate,
  getDatesDefault,
  getHiringDaysCount,
  nextDayFromCurrDayDate,
} from 'utils/datesСalculation';

export const useCalendarSettings = (product, dates) => {
  const state = useStore(picker_state$);
  const { currLocaleDate, isCurrentDayHirePossible } = useStore(
    productCalendarSettings$,
  );

  const { deliveryWeekends, defaultHiringPeriod, finalBlockedDates } =
    useGetProductDatesSettings();

  const [selectedValues, setSelectedValues] = useState({
    startDate: dates?.start,
    endDate: dates?.end,
  });

  useEffect(() => {
    return () => {
      setSelectedValues({
        startDate: null,
        endDate: null,
      });
      changeFocusedPickerDay('start');
    };
  }, []);

  const onDatesChanges = useCallback(
    (e, isPrefilled) => {
      const startDate = formatDate(e.startDate);
      let endDate = formatDate(e.endDate);

      if (!isPrefilled && state?.focusedInput === 'startDate') {
        endDate = null;
      }

      setSelectedValues({ startDate, endDate });
      changeDatesRange({ startDate, endDate });
    },
    [state],
  );

  const blockedDatesWithDaysMargin = useMemo(() => {
    return getDatesDefault({
      product,
      deliveryWeekends,
      type: 'listOfBlockedDates',
      defaultHiringPeriod,
      finalBlockedDates,
      isCurrentDayHirePossible,
      currLocaleDate,
    });
  }, [
    product,
    currLocaleDate,
    defaultHiringPeriod,
    deliveryWeekends,
    finalBlockedDates,
    isCurrentDayHirePossible,
  ]);

  const minBookingDays = useMemo(() => {
    if (!product || !product.min_period_days) {
      return 1;
    }

    return product.min_period_days;
  }, [product]);

  const hiringDaysCount = useMemo(() => {
    return getHiringDaysCount(
      selectedValues.endDate && selectedValues.startDate
        ? {
            dates: {
              start: selectedValues.startDate,
              end: selectedValues.endDate,
            },
          }
        : null,
    );
  }, [selectedValues]);

  const minBookingDate = isCurrentDayHirePossible
    ? currLocaleDate
    : nextDayFromCurrDayDate;

  return useMemo(
    () => ({
      selectedValues,
      onDatesChanges,
      blockedDatesWithDaysMargin,
      hiringDaysCount,
      minBookingDays,
      minBookingDate,
    }),
    [
      blockedDatesWithDaysMargin,
      hiringDaysCount,
      minBookingDays,
      onDatesChanges,
      selectedValues,
      minBookingDate,
    ],
  );
};
